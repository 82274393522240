import classNames from 'classnames';
import Link from 'next/link';
import { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import NavItem from './NavItem';

export default function NavMenu({ topLinks, compactNav, mobile, supportURL }) {
    const styles = useContext(StyleContext),
        linkAttr = {
            'data-position': 'nav',
        };

    return (
        <div className={styles.viomeNav__topLinks}>
            <ul className={styles.viomeNav__main}>
                {compactNav ? (
                    <NavItem
                        linkData={{
                            url: '/products',
                            title: 'Products',
                        }}
                    />
                ) : (
                    topLinks.map((topLink) => {
                        const linkData = topLink?.fields,
                            nestedLinks = linkData?.dropdownLinks;

                        return (
                            <NavItem
                                linkData={linkData}
                                nestedLinks={nestedLinks}
                                key={linkData.title}
                            />
                        );
                    })
                )}
            </ul>
            {mobile && (
                <div className={styles.viomeNav__bottomLinks}>
                    <p>My Viome</p>
                    <Link href="https://my.viome.com/"
                          className={classNames(styles.viomeNav__btn)}
                          {...linkAttr}
                    >
                        Login
                    </Link>
                    <Link href="https://my.viome.com/registration/welcome" className={classNames(styles.viomeNav__btn)}
                          {...linkAttr}>

                        Register
                    </Link>
                    {supportURL && (
                        <Link href={supportURL} className={classNames(styles.viomeNav__btn)}
                              {...linkAttr}>
                            Support
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
}

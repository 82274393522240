import { useState, useEffect } from 'react';
import useSWR from 'swr';
import * as Sentry from '@sentry/nextjs';

export default function useShopify(product) {
    const [productData, setProductData] = useState(null),
        fetcher = (url, product) =>
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({ product }),
            })
                .then((res) => res.json())
                .catch((e) => {
                    Sentry.captureException(e);
                    console.error({ error: e });
                }),
        { data: shopifyProductData, error } = useSWR(
            [`/api/shopify`, product],
            fetcher,
        );

    useEffect(() => {
        if (!error && shopifyProductData) {
            setProductData(shopifyProductData?.data);
        }
    }, [shopifyProductData, error]);

    return productData;
}

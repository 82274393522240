import React, { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import { applyHashMarkToHexCode, cBoolean , parseModules } from '@viome/vnxt-nucleus';
import { StyleContext } from 'utils/context/StyleContextProvider';
import TailwindRow from 'components/Tailwind/Row';
import NavLinks from './NavLinks';

export default function NavItem({ linkData, nestedLinks }) {
    const styles = useContext(StyleContext),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            dropdownRef,
            false,
            false,
            true,
        ),
        toggleHandler = (e) => {
            setIsActive(!isActive);
            e.preventDefault();
        },
        toggleOff = () => setIsActive(false),
        hasNested = nestedLinks && nestedLinks.length > 0,
        isProduct = cBoolean(linkData?.isProductDropdown),
        itemURL = linkData?.url,
        itemTitle = linkData?.title,
        itemText = linkData?.linkText || itemTitle,
        navSection = linkData?.section,
        backgroundColor = linkData?.backgroundColor,
        background = applyHashMarkToHexCode(backgroundColor),
        darkMode = cBoolean(linkData?.darkMode),
        splitLinks = cBoolean(linkData?.splitLinks),
        parsedPopup = navSection && parseModules([navSection])[0],
        linkAttr = {
            'data-position': 'nav',
        },
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        });

    useEffect(() => {
        const handleRouteChange = () => {
            setIsActive(false);
        };

        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    let menuProps = {};
    if (hasNested) {
        menuProps = {
            onClick: toggleHandler,
            onMouseOver: toggleHandler,
            onFocus: toggleHandler,
            onKeyPress: toggleHandler,
            role: 'link',
            tabIndex: 0,
        };
    }

    const navItemProps = {
        ...menuProps,
        className: classNames(styles.viomeNav__link, isActive && styles.active),
        ...linkAttr,
    };

    return (
        <li
            className={classNames(
                styles.viomeNav__item,
                hasNested && styles.click,
                isProduct ? styles.product : null,
            )}
            onMouseLeave={toggleOff}
        >
            <ConditionalWrapper
                condition={cBoolean(itemURL)}
                wrapper={(children) => (
                    <Link href={itemURL} {...navItemProps}>
                        {children}
                    </Link>
                )}
                elseWrapper={(children) => (
                    <div {...navItemProps} id={`${children}-navItem`}>
                        {children}
                    </div>
                )}
            >
                {isProduct ? itemTitle : itemText}
            </ConditionalWrapper>
            {hasNested && (
                <div
                    onMouseLeave={toggleOff}
                    ref={dropdownRef}
                    className={classNames(
                        styles['viomeNav__dropdown-menu'],
                        darkMode && styles.dark,
                    )}
                    style={{
                        backgroundColor: background,
                    }}
                >
                    <div
                        className={classNames(
                            styles['viomeNav__dropdown-links'],
                            splitLinks && styles.split,
                        )}
                    >
                        <NavLinks
                            nestedLinks={nestedLinks}
                            linkAttr={linkAttr}
                            callback={toggleOff}
                            additional={{
                                itemText,
                                itemURL,
                            }}
                        />

                        {itemTitle === 'Tests' && (
                            <div className={classNames(styles.product__button)}>
                                <CTA
                                    data={{
                                        style: 'ForwardArrow',
                                        text: itemText,
                                        url: itemURL,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {parsedPopup && <TailwindRow data={parsedPopup} />}
                </div>
            )}
        </li>
    );
}

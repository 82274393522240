import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { parseURL, PinterestIcon as Pinterest } from '@viome/vnxt-nucleus';
import classNames from 'classnames';
import EmbedSignUpForm from 'components/forms/EmbedSignUp';
import Link from 'next/link';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { APP_NAME, KLAVIYO, VIOME_COM_URL } from 'utils/constants';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import styles from './Footer.module.scss';

export default function Footer({ data, compactFooter, references }) {
    const logo = data?.logo,
        { EMBED_FOOTER_SIGNUP_FORM_ID, EMBED_CD_SIGNUP_FORM_ID } = KLAVIYO,
        topLinks = data?.topLinks.items,
        bottomLinks = data?.bottomLinks.items,
        socialLabels = {
            instagram: 'Instagram',
            facebook: 'Facebook',
            pinterest: 'Pinterest',
            twitter: 'Twitter',
            vimeo: 'Vimeo',
            youtube: 'YouTube',
        },
        launchForethought = (e) => {
            e.preventDefault();
            const { Forethought } = window;
            if (Forethought) {
                Forethought('widget', 'show');
                Forethought('widget', 'open');
            }
        };

    return (
        <StyleContextProvider styles={styles}>
            <footer
                className={classNames(
                    styles.viome__footer,
                    compactFooter && styles.compact,
                )}
            >
                <div
                    className={classNames(
                        styles.footerWrapper,
                        compactFooter && styles.compact,
                    )}
                >
                    {!compactFooter && (
                        <div className={styles.footerTop}>
                            <div>
                                {APP_NAME === "viome-cd" &&
                                    <div className={
                                            styles.footerTop__LogoContainer
                                        }>
                                            {logo && (
                                            <Link href={VIOME_COM_URL}>
                                                <img
                                                    src={logo.url}
                                                    alt={logo.title}
                                                    type={logo.contentType}
                                                    loading="lazy"
                                                />
                                            </Link>
                                            )}
                                    </div>
                                }
                                {APP_NAME !== "viome-cd" &&
                                    <div className={styles.socialSection}>
                                        <a
                                            aria-label={socialLabels.instagram}
                                            className={classNames(
                                                styles.footerLink,
                                                styles['footerLink--ig'],
                                            )}
                                            href={data.instagram}
                                        >
                                            <img
                                                src="https://images.ctfassets.net/qk4l4jfatr3e/3J3Sb98vME6THkJja8dPUV/5c4d097e044829f98ec602fb308f983b/instagram-icon.svg"
                                                alt={socialLabels.instagram}
                                                type="image/svg+xml"
                                                loading="lazy"
                                            />
                                        </a>
                                        <a
                                            aria-label={socialLabels.facebook}
                                            className={classNames(
                                                styles.footerLink,
                                                styles['footerLink--fb'],
                                            )}
                                            href={data.facebook}
                                        >
                                            <img
                                                src="https://images.ctfassets.net/qk4l4jfatr3e/EjiAEnWI8dpegXpUzrkC5/0b509578f6f32ff101a388400d1042b3/facebook-icon.svg"
                                                alt={socialLabels.facebook}
                                                type="image/svg+xml"
                                                loading="lazy"
                                            />
                                        </a>
                                        <a
                                            aria-label={socialLabels.pinterest}
                                            className={classNames(
                                                styles.footerLink,
                                                styles['footerLink--pinterest'],
                                            )}
                                            href={data.pinterest}
                                        >
                                            <Pinterest />
                                        </a>
                                        <a
                                            aria-label={socialLabels.twitter}
                                            className={classNames(
                                                styles.footerLink,
                                                styles['footerLink--tw'],
                                            )}
                                            href={data.twitter}
                                        >
                                            <img
                                                src="https://images.ctfassets.net/qk4l4jfatr3e/3EUmH2EuqO2UGo3Z6qTi3f/67fc2ab1ae58d2f7cbea38bb72685a8f/x-icon.svg"
                                                alt={socialLabels.twitter}
                                                type="image/svg+xml"
                                                loading="lazy"
                                            />
                                        </a>
                                        <a
                                            aria-label={socialLabels.youtube}
                                            className={classNames(
                                                styles.footerLink,
                                                styles['footerLink--yt'],
                                            )}
                                            href={data.youtube}
                                        >
                                            <img
                                                src="https://images.ctfassets.net/qk4l4jfatr3e/72jJQsaqRJ3T3r7hXl9yEV/22507c0d85eafc66500b0e949aedcf9e/youtube-icon.svg"
                                                alt={socialLabels.youtube}
                                                type="image/svg+xml"
                                                loading="lazy"
                                            />
                                        </a>
                                        {/*
                                            <a
                                                aria-label={socialLabels.vimeo}
                                                className={classNames(
                                                    styles.footerLink,
                                                    styles['footerLink--v'],
                                                )}
                                                href={data.vimeo}
                                            >
                                                <img
                                                    src="https://images.ctfassets.net/qk4l4jfatr3e/DC0pPXytk2F0GqUZZo4B2/56b691862cde88cd963be72b25724eee/Vimeo-Icon-01.svg"
                                                    alt={socialLabels.vimeo}
                                                    type="image/svg+xml"
                                                    loading="lazy"
                                                />
                                            </a>
                                            */}
                                    </div>
                                }
                                <div className={styles.footerTop__EmbedForm}>
                                    {APP_NAME === "viome" && <EmbedSignUpForm
                                        formId={EMBED_FOOTER_SIGNUP_FORM_ID}
                                    />}
                                    {APP_NAME === "viome-cd" && <EmbedSignUpForm
                                        formId={EMBED_CD_SIGNUP_FORM_ID}
                                    />}
                                </div>
                            </div>
                            {topLinks && (
                                <div className={styles.topLinks}>
                                    {topLinks.map((topLink) => {
                                        const nestedLinks =
                                                topLink?.dropdownLinks.items,
                                            hasNested =
                                                nestedLinks &&
                                                nestedLinks.length > 0;

                                        return (
                                            <div
                                                className={styles.topLink}
                                                key={topLink?.title}
                                            >
                                                <p
                                                    className={
                                                        styles.topLinkHead
                                                    }
                                                >
                                                    <ConditionalWrapper
                                                        condition={!hasNested}
                                                        wrapper={(children) => (
                                                            <Link
                                                                href={
                                                                    topLink.url
                                                                }
                                                            >
                                                                {children}
                                                            </Link>
                                                        )}
                                                    >
                                                        {topLink?.title}
                                                    </ConditionalWrapper>
                                                </p>
                                                {hasNested && (
                                                    <>
                                                        {nestedLinks.map(
                                                            (nestedLink) => {
                                                                const ctaOptions =
                                                                        {},
                                                                    linkText =
                                                                        nestedLink.title;
                                                                if (
                                                                    linkText ===
                                                                    'Contact Us'
                                                                ) {
                                                                    ctaOptions.onClick =
                                                                        launchForethought;
                                                                    ctaOptions.onKeyPress =
                                                                        launchForethought;
                                                                    ctaOptions.role =
                                                                        'button';
                                                                    ctaOptions.tabIndex = 0;
                                                                }

                                                                return (
                                                                    <Link
                                                                        href={parseURL(
                                                                            nestedLink.url,
                                                                        )}
                                                                        key={
                                                                            nestedLink.title
                                                                        }
                                                                        className={
                                                                            styles.footerLink
                                                                        }
                                                                        {...ctaOptions}
                                                                    >
                                                                        {
                                                                            nestedLink.title
                                                                        }
                                                                    </Link>
                                                                );
                                                            },
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames(
                            styles.footerCopy,
                            styles.footerText,
                        )}
                        id="footer-text"
                    >
                        {references && documentToReactComponents(references)}
                        {documentToReactComponents(data.footerText.json)}
                    </div>

                    {data.fsa && (
                        <div
                            className={classNames(
                                styles.fsa,
                                styles.footerCopy,
                            )}
                            id="footer-fsa"
                        >
                            {documentToReactComponents(data.fsa?.json)}
                        </div>
                    )}

                    <div className={styles.fdaCompliance} id="footer-fda">
                        {documentToReactComponents(data.fdaCompliance.json)}
                    </div>
                    <hr />
                    <div className={styles.footerBottom} id="footer-bottom">
                        <div className={styles.footerDetailsWrapper}>
                            <div className={styles.footerCopyrightWrapper}>
                                <p className={styles.footerCopyright}>
                                    {new Date().getFullYear()} Viome Life
                                    Sciences, Inc. All rights reserved.
                                </p>
                            </div>
                            <div className={styles.bottomLinks}>
                                {bottomLinks && (
                                    <>
                                        {bottomLinks.map((bottomLink) => (
                                            <Link
                                                href={parseURL(bottomLink.url)}
                                                key={bottomLink.title}
                                                className={
                                                    styles.footerLink
                                                }
                                            >
                                                {bottomLink.title}
                                            </Link>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </StyleContextProvider>
    );
}

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router from 'next/router';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { useViewport } from 'utils/context/ViewportContextProvider';
import NavAccountDropdown from './NavAccountDropdown';
import NavMenu from './NavMenu';
import { getMousePosition, getRectangle, isInsideRectangle } from './NavUtils';
import styles from './Nav.module.scss';
import { APP_NAME } from 'utils/constants';

export default function Nav({
    stickyBanner,
    data,
    isStickyNav,
    compactNav,
    shell,
}) {
    let mousePosition = {};

    const dropdownRef = useRef(null),
        { width } = useViewport(),
        [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false),
        pendingExpand = {
            item: null,
            timeoutId: null,
        },
        [expandedItem, setExpandedItem] = useState(null),
        isDesktopNav = () => {
            if (window) {
                return window.innerWidth > 1200;
            }
            return false;
        },
        clearPendingExpand = () => {
            if (pendingExpand && pendingExpand.timeoutId) {
                clearTimeout(pendingExpand.timeoutId);
            }
            pendingExpand.item = null;
        },
        updateExpand = (item) => {
            // if there is nothing pending don't update (happens when events fire really fast)
            if (!pendingExpand.item) return;
            // No point updating if its the same item
            if (expandedItem === pendingExpand.item) return;

            setExpandedItem(item);
            mousePosition = {};
            clearPendingExpand();
        },
        checkAim = (item) => {
            if (!mousePosition) return false;
            const currentPosition = mousePosition,
                submenuBox = getRectangle(item.current);
            // Check to see if the user is inside the submenu

            return submenuBox && currentPosition
                ? isInsideRectangle(submenuBox, currentPosition)
                : false;
        },
        onItemEnter = (item) => {
            // The item entered will now be the pendingExpand
            if (isDesktopNav()) {
                clearPendingExpand();
                pendingExpand.item = item;
                // If no currently expanded item
                if (!expandedItem) {
                    return updateExpand(item);
                }
                // If the user isn't on the the way to the currently expanded submenu
                if (!checkAim(item)) {
                    return updateExpand(item);
                }
            }
            return true;
        },
        // When the user leaves a menu item, setup an interval to check if user
        // in in the submenu. check every 550ms
        onItemLeave = (item) => {
            if (isDesktopNav()) {
                pendingExpand.timeoutId = setInterval(() => {
                    if (!checkAim(item)) {
                        pendingExpand.item = {};
                        updateExpand(null);
                    }
                }, 550);
            }
        },
        // Store a history of mouse positions so we can calculate user's aim
        onMouseMove = (e) => {
            if (isDesktopNav()) {
                const pos = getMousePosition(e.nativeEvent);
                mousePosition = pos;
            }
        },
        onClick = () => setIsActive(!isActive),
        linkAttr = {
            'data-position': 'nav',
        },
        logo = data?.logo?.fields.file,
        topLinks = data?.topLinks,
        shopText = data?.shopText,
        shopURL = data?.shopUrl,
        supportURL = data?.supportUrl,
        navLinks = (mobile) =>
            topLinks ? (
                <NavMenu
                    topLinks={topLinks}
                    compactNav={compactNav}
                    mobile={mobile}
                    supportURL={supportURL}
                    hoverProps={{
                        expandedItem,
                        onItemEnter,
                        onItemLeave,
                    }}
                />
            ) : null;

    useEffect(() => {
        const bodyEl = document.querySelector(`body`),
            handleRouteChange = () => {
                setIsActive(false);
                pendingExpand.item = {};
                updateExpand(null);
            };

        if (width < 1200) {
            if (isActive) {
                bodyEl.classList.add('navActive');
            } else {
                bodyEl.classList.remove('navActive');
            }
        } else {
            bodyEl.classList.remove('navActive');
        }

        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [width, isActive]);

    return (
        data && (
            <StyleContextProvider styles={styles}>
                <header
                    className={classNames(
                        styles.viomeNav,
                        stickyBanner && styles.hasStickyBanner,
                        isStickyNav && styles.isStickyNav,
                        shell === 'Transparent' && styles.transparent,
                        isActive && styles.active,
                    )}
                    onMouseMove={onMouseMove}
                >
                    <nav className={styles.viomeNav__wrapper}>
                        <div className={styles.viomeNav__left}>
                            <button
                                onClick={onClick}
                                className={classNames(styles.viomeNav__menu)}
                                type="button"
                                aria-label="Close"
                            >
                                <div className={styles.viomeNav__menu__wrapper}>
                                    <div
                                        className={styles.viomeNav__menu__bar}
                                    />
                                </div>
                            </button>
                            <Link href="/" {...linkAttr}>
                                <img
                                    src={logo.url}
                                    alt="Viome logo"
                                    type={logo.contentType}
                                />
                            </Link>
                        </div>
                        <div className={styles.viomeNav__right}>
                            {navLinks(false)}
                            <div className={styles.viomeNav__misc}>
                                {(APP_NAME === "viome" || APP_NAME === "viome-cd") && !compactNav && (
                                    <>
                                        {supportURL && (
                                            <Link
                                                href={supportURL}
                                                className={classNames(
                                                    styles.viomeNav__link,
                                                    styles.support,
                                                )}
                                                {...linkAttr}
                                            >
                                                Support
                                            </Link>
                                        )}
                                        <Link
                                            href="https://buy.viome.com/cart"
                                            className={classNames(
                                                styles.viomeNav__link,
                                                styles.cart,
                                            )}
                                            {...linkAttr}
                                        >
                                            Cart
                                        </Link>
                                        <NavAccountDropdown />
                                    </>
                                )}
                                {shopURL && shopText && (
                                    <Link
                                        href={shopURL}
                                        className={classNames(
                                            styles.viomeNav__link,
                                            styles.shop,
                                        )}
                                        {...linkAttr}
                                    >
                                        {shopText}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <section
                            ref={dropdownRef}
                            className={classNames(
                                styles.viomeDropdown,
                                isActive && styles.active,
                                stickyBanner && styles.hasStickyBanner,
                            )}
                            id="viomeDropdown_main"
                        >
                            {navLinks(true)}
                        </section>
                    </nav>
                </header>
            </StyleContextProvider>
        )
    );
}

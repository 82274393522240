import React, { useRef, useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import { StyleContext } from 'utils/context/StyleContextProvider';

export default function NavAccountDropdown() {
    const styles = useContext(StyleContext),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            dropdownRef,
            false,
            false,
            true,
        ),
        toggleHandler = (e) => {
            setIsActive(!isActive);
            e.preventDefault();
        },
        linkAttr = {
            'data-position': 'nav',
        };

    return (
        <ul className={styles.account}>
            <li className={classNames(styles.viomeNav__item, styles.click)}>
                <div
                    onMouseOver={toggleHandler}
                    onFocus={toggleHandler}
                    onMouseLeave={toggleHandler}
                    role="link"
                    className={classNames(
                        styles.viomeNav__link,
                        isActive && styles.active,
                    )}
                    tabIndex={0}
                >
                    My Account
                </div>
                <div
                    onMouseLeave={toggleHandler}
                    ref={dropdownRef}
                    className={styles['viomeNav__dropdown-menu']}
                >
                    <Link href="https://my.viome.com/"
                          className={classNames(
                                styles.viomeNav__link,
                                styles['viomeNav__link--dropdown'],
                        )}
                          {...linkAttr}
                    >
                        Login
                    </Link>
                    <Link href="https://my.viome.com/registration/welcome"
                          className={classNames(
                            styles.viomeNav__link,
                            styles['viomeNav__link--dropdown'],
                        )}
                          {...linkAttr}
                    >
                        Register
                    </Link>
                </div>
            </li>
        </ul>
    );
}

import React, { useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getProductURL } from '@viome/vnxt-nucleus';
import { StyleContext } from 'utils/context/StyleContextProvider';
import { PromoContext } from 'utils/context/PromoContextProvider';

export default function NavProduct({ product, toggleOff, additional }) {
    const styles = useContext(StyleContext),
        promoContext = useContext(PromoContext),
        hideProducts = promoContext.hideProducts || [],
        { name, displayName, kicker, slug, sku } = product,
        linkAttr = {},
        productURL = getProductURL(sku, slug),
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        });

    if (slug === 'cancer-detect') {
        linkAttr.target = '_blank';
    }

    return (
        <>
            {!hideProducts.includes(sku) && (
                <>
                    {slug === 'cancer-detect' && additional?.itemText && (
                        <div className={classNames(styles.cta)}>
                            <CTA
                                data={{
                                    style: 'ForwardArrow',
                                    text: additional?.itemText,
                                    url: additional?.itemURL,
                                }}
                            />
                        </div>
                    )}
                    <Link href={productURL} passHref
                          onClick={toggleOff}
                          onKeyPress={toggleOff}
                          tabIndex={0}
                          role="link"
                          className={classNames(
                                styles.viomeNav__link,
                                styles['viomeNav__link--dropdown'],
                                styles['viomeNav__link--product'],
                                styles[sku],
                                sku,
                            )}
                          data-position="nav"
                          {...linkAttr}
                      >
                        <div className={styles.text}>
                            <p className={styles.productName}>
                                {displayName || name}
                            </p>
                            <p className={styles.kicker}>{kicker}</p>
                        </div>
                    </Link>
                </>
            )}
        </>
    );
}

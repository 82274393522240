import React, { useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { cBoolean, parseURL } from '@viome/vnxt-nucleus';
import { StyleContext } from 'utils/context/StyleContextProvider';
import NavProduct from './NavProduct';

export default function NavLinks({
    nestedLinks,
    linkAttr,
    additional,
    callback,
}) {
    if (nestedLinks) {
        const styles = useContext(StyleContext),
            linkProps = { ...linkAttr },
            allLinks = [],
            desktopLinks = [],
            parseLink = (nestedLinkData, index, nested) => {
                const nestedLinks = nestedLinkData?.dropdownLinks,
                    hasNested = nestedLinks && nestedLinks.length > 0;

                if (hasNested) {
                    nestedLinks.forEach((nestedLink, index) => {
                        const nestedLinkData = nestedLink?.fields;
                        parseLink(nestedLinkData, index, true);
                    });
                } else {
                    const linkEl = nestedLinkData.url ? (
                        <Link
                            href={parseURL(nestedLinkData.url)}
                            key={nestedLinkData.title}
                            onClick={callback}
                            onKeyPress={callback}
                            role="link"
                            tabIndex={0}
                            className={classNames(
                                styles.viomeNav__link,
                                styles['viomeNav__link--dropdown'],
                                cBoolean(nestedLinkData.hideAtDesktop) &&
                                    !nested &&
                                    styles.desktop,
                            )}
                            {...linkProps}
                        >
                            {nestedLinkData.title}
                        </Link>
                    ) : (
                        <NavProduct
                            product={nestedLinkData}
                            index={index}
                            toggleOff={callback}
                            additional={additional}
                            key={nestedLinkData.sku}
                        />
                    );
                    if (nested) {
                        desktopLinks.push(linkEl);
                    } else {
                        allLinks.push(linkEl);
                    }
                }
            };

        nestedLinks.forEach((nestedLink, index) => {
            const nestedLinkData = nestedLink?.fields;
            parseLink(nestedLinkData, index);
        });

        return (
            <>
                <div className={classNames(styles.linksWrapper)}>
                    {allLinks}
                </div>
                <div
                    className={classNames(
                        styles.linksWrapper,
                        styles.desktopNav,
                    )}
                >
                    {desktopLinks}
                </div>
            </>
        );
    }
}
